import React, { Component } from 'react';
import Logo from './Logo';

class PreviewConceptfactory30Years extends Component {
    render() {
        return (
            <div className="preview">
                <div className="preview__container preview--conceptfactory" style={{fontSize: '10px',fontFamily: 'Arial, sans-serif',padding: 0,margin: 0,color: '#000000',
                    width: '100%'}}>

                    <table cellPadding="0" cellSpacing="0" border="0">
                        <tbody>
                            <tr>
                                <td>
                                    <p style={{ fontSize: '12px', fontFamily: 'Arial, sans-serif', fontWeight:700, textDecoration: 'none', marginBottom:10, marginTop:0}}>
                                        <a x-apple-data-detectors="true" href={'mailto:' + this.props.person.email} style={{color: '#1504dd', fontSize: '12px',fontFamily:'Arial,sans-serif',textDecoration:'none'}}><b style={{color: '#1504dd'}}>{this.props.person.firstname}&nbsp;{this.props.person.lastname}</b></a>
                                        <br/>
                                        <span style={{fontSize: '11px', fontWeight: 400}}>{this.props.person.jobtitle}</span>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding="0" cellSpacing="0" border="0" style={{paddingBottom:10}}>
                                        <tbody>
                                            <tr>
                                                <td style={{paddingRight:20}}>
                                                    <img src={Logo('30ans')} alt="30 years" style={{border:0, width:90, height:70}} width="90" height="70" id="logo30" />
                                                </td>
                                                <td>
                                                    <a x-apple-data-detectors="true" href="https://conceptfactory.lu/unlock-2025/"><img src={Logo('unlock')} alt="Together, let's unlock tomorrow!" style={{border:0, width:202, height:70}} width="202" height="70" id="unlock" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding="0" cellSpacing="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td><img src={Logo('conceptfactory30')} alt="Concept Factory" style={{border:0, width:90, height:42}} width="90" height="42" id="conceptfactorylogo" /></td>
                                                <td style={{verticalAlign:'bottom', paddingLeft:20, paddingRight:15, paddingBottom:5}}>
                                                    <p x-apple-data-detectors="true" style={{color:'#002f5e',fontSize:'10px',fontFamily:'Arial, sans-serif',margin:0,padding:0,paddingRight:15,borderRight:'2px solid #ff3c61'}}>21, rue de Pont-R&eacute;my<br/>L-2423&nbsp;Luxembourg</p>
                                                </td>
                                                <td style={{verticalAlign:'bottom', paddingBottom:5}}>
                                                    <p x-apple-data-detectors="true" style={{color:'#002f5e',fontSize:'10px',fontFamily:'Arial, sans-serif',margin:0,padding:0,}}>T.&nbsp;{this.props.person.phone}<br/>{this.props.person.gsm !== '' && (<>M.&nbsp;{this.props.person.gsm}<br/></>)}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p style={{marginTop:10, marginBottom:10}}><a x-apple-data-detectors="true" href="http://www.conceptfactory.lu" style={{color: '#ff3c61', fontSize: '11px', fontFamily: 'Arial, sans-serif', textDecoration: 'none'}}><b style={{color: '#ff3c61'}}>{this.props.person.company}.lu</b></a></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {this.props.person.badge !== '' && (<>
                                        <a href="https://google.com/partners/agency?id=3036082782"><img src={Logo('ggp')} alt="Google Partner" style={{border:0,width:'87px',height:'90px'}} width="87" height="90" id="g-p" /></a>
                                        <a href="https://www.facebook.com/business/marketing-partners"><img src={Logo('fbp')} alt="Facebook Marketing Partner" style={{border:0,width:'127px',height:'71px'}} width="127" height="71" id="f-p" /></a>
                                    </>)}
                                    <a href="https://www.wearetribeglobal.com" style={{display:'inline-block', color: '#000000',textDecoration:'none'}}><span style={{fontSize:9}}>Member of</span><br/><img src={Logo('tribe-global')} alt="Member of Tribe Global" style={{border:0,width:64,height:36,marginTop:4,marginBottom:6}} width="64" height="36" id="tribeglobal" /></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default PreviewConceptfactory30Years;