import React, { Component } from 'react';
import './assets/css/App.css';
import Sidebar from './components/Sidebar';
import Preview from './components/Preview';
import Login from './components/Login';
import Logout from './components/Logout';
import Download from './components/Download';
import InteractLogo from './assets/img/logo-interact.png';
//import InsertSignature from './components/InsertSignature';
import EmailClient from './components/EmailClient';
import Instructions from './components/Instructions';
import mailIcon from './assets/img/Mail.png';
import outlookIcon from './assets/img/Outlook.png';
import thunderbirdIcon from './assets/img/Thunderbird.png';
import axios from 'axios';

const emailClients = [
    {
        name: 'Mozilla Thunderbird',
        slug: 'thunderbird',
        icon: thunderbirdIcon,
    },
    {
        name: 'Apple Mail',
        slug: 'mail',
        icon: mailIcon,
    },
    {
        name: 'Microsoft Outlook',
        slug: 'outlook',
        icon: outlookIcon,
    }
];

const appTranslations = {
  fr: "Insérez votre signature dans votre client e-mail",
  en: "Insert your signatur in your email client"
}

class App extends Component {
  constructor(props) {
    super(props);

    this.API = process.env.REACT_APP_API_URL;
    this.APP = process.env.REACT_APP_APP_URL;


    this.state = {
      userLang: 'fr',
      person: {
        company: '',
        firstname: '',
        lastname: '',
        jobtitle: '',
        email: '',
        phone: '',
        gsm: '',
        badge:'',
        wishes:'',
        disclaimer: '',
      },
      login: {
        usr: '',
        pwd: '',
      },
      emailClient: '',
    };
  }

  componentDidMount() {
    
    this.setState({ userLang: document.querySelector('html').getAttribute('lang') })
  }

  handleInputChange(e) {
    const value = e.currentTarget.value;
    const property = e.currentTarget.name;

    let person = this.state.person;
    const keys = Object.keys(person);

    if (keys.includes(property)) {
      person[property] = value;
    }

    let emailDomain;
    if (['interact', 'conceptfactory', 'sustain', 'exxus','innohub'].includes(person.company)) {
      emailDomain = person.company + '.lu';
    } else if (['lexfield', 'fidefield'].includes(person.company)) {
      emailDomain = person.company + '.com';
    }

    if (property === 'firstname') {
      person['email'] = value.substring(0, 1).toLowerCase() + person['lastname'].toLowerCase() + '@' + emailDomain;
    }

    if (property === 'lastname') {
      person['email'] = person['firstname'].substring(0, 1).toLowerCase() + value.toLowerCase() + '@' + emailDomain;
    }

    this.setState({person: person});
  }

  handleLoginChange(e) {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    let login;

    if (name === 'login') {
      login = this.state.login;
      login.usr = value;
    }

    if (name === 'password') {
      login = this.state.login;
      login.pwd = value;
    }

    this.setState({login: login})
  }

  async handleLoginSubmit(e) {
    let login = this.state.login;
    let person  = this.state.person;

    await axios
      .post(this.API + '?action=login', {
        usr: this.state.login.usr,
        pwd: this.state.login.pwd
      }, {
        headers : {
          "Content-Type": 'application/x-www-form-urlencoded'
        }
      })
      //.then(response => response.json())
      .then(response => {
        if (response.data.login !== '' && response.data.active === 1) {
          person.company = login.usr;
          if (person.company === 'interact' || person.company === 'conceptfactory') {
            person.phone = '(+352) 48 49 69 1';
          }
        } else {
          alert('Invalid credentials');
        }
      })
      .catch(error => console.log(error));
    this.setState({ person: person });
  }

  handleLogout(e) {
    const login = this.state.login;
    login.usr = '';
    login.pwd = '';
    
    this.setState({ login: login });
    window.location.reload(false);

  }

  dowloadHtml(e) {
    e.preventDefault();
    let html = '';

    if (this.state.person.company === 'conceptfactory') {
      html = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><title>${this.state.person.email}</title><meta http-equiv="Content-Type" content="text/html; charset=utf-8" /><style type="text/css"><!-- a{text-decoration: none;} -->a[x-apple-data-detectors] {color: #002f5e !important; text-decoration: none !important; }</style></head><body style="font-size:10px;font-family:Arial, sans-serif;padding:0;margin:0;color:#002f5e;width:100%;">`;
    } else {
      html = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><title>${this.state.person.email}</title><meta http-equiv="Content-Type" content="text/html; charset=utf-8" /><style type="text/css"><!-- table{font-family: Arial, Helvetica, sans-serif;margin:0;padding:0;} table tr{margin:0;padding:0;} .txt {font-size: 11px;color: #000000;line-height: 13px;} .txt a{color: #000000;} a[x-apple-data-detectors] {color: inherit !important;text-decoration: none !important;}--></style></head><body style="font-family:Arial,Helvetica,sans-serif;padding:0;margin:0;color:#000000;">`;
    }
    html = html + document.querySelector('.preview__container').innerHTML;
    html = html + '</body></html>';
    const blob = new Blob([html], {type: "text/html;charset=utf-8"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${this.state.person.email}.html`;
    link.click();
  }

  handleEmailClientChoice(e) {
    console.log(e);
    let emailClient = this.state.emailClient;
    emailClient = e;
    this.setState({ emailClient: emailClient });
  }

  closeOverlayer() {
    let emailClient = this.state.emailClient;
    emailClient = '';
    this.setState({ emailClient: emailClient });
  } 

  render() {
    if (this.state.person.company !== '') {
      return (
        <div className="app">
          <div className="app__container">
            <div className="header">
              <div className="signator-logo">
                <img src={InteractLogo} alt="Interact" />
                <p>Signator</p>
              </div>
              <Logout login={this.state.login} onClick={(e) => this.handleLogout(e)} />
            </div>
            
            <Sidebar person={this.state.person} userLang={this.state.userLang} onChange={(e) => this.handleInputChange(e)} />
            <div className="result">
              <Preview person={this.state.person} userLang={this.state.userLang} />
            </div>
            { this.state.person.firstname !== "" && this.state.person.lastname !== "" && <Download userLang={this.state.userLang} onClick={(e) => this.dowloadHtml(e)} /> }

            {this.state.person.firstname !== "" && this.state.person.lastname !== "" &&
              (<div className="insert-signature">
                  <h2 className="title">4. {appTranslations[this.state.userLang]}</h2>

                  {emailClients.map((emailClient) => {
                    return (<EmailClient 
                      key={emailClient.slug} 
                      name={emailClient.name} 
                      icon={emailClient.icon}
                      onClick={(e) => this.handleEmailClientChoice(emailClient.slug)} 
                    />);
                  })}
              </div>)
            }

            { this.state.emailClient !== "" && <Instructions emailClient={this.state.emailClient} userLang={this.state.userLang} onClose={() => this.closeOverlayer()} /> }

          </div>
        </div>
      )
    } else {
      return (
        <div className="app app--starter">
          <div className="app__container">
            <div className="header">
              <div className="signator-logo">
                <img src={InteractLogo} alt="Interact" />
                <p>Signator</p>
              </div>
            </div>
            <Login onChange={(e) => this.handleLoginChange(e)} onSubmit={(e) => this.handleLoginSubmit(e)} />
          </div>
        </div>
      )
    }
  }
}

export default App;
